<template>
  <div>
    <div class="mt-4">
      <b-form ref="stepForm" v-if="content" class="my-5">
        <!-- <div @click="autoWriteHandle">{{ $t('自動填寫') }}</div> -->
        <div @click="autoWriteHandle" v-show="autoWrite == 'true' ? true : false">{{ $t('自動填寫') }}</div>
        <h4 class="my-4 type-txt">{{ $t('申請職位') }}</h4>
        <b-card>
          <b-form-group
            :label="`${$t('首選職位')}`"
            label-for="firstjobid"
            label-cols-sm="4"
            label-cols-lg="2"
            class="_required"
          >
            <b-form-select
              id="firstjobid"
              v-model="form.firstJobId"
              :options="jobs"
              disabled-field="notEnabled"
              v-validate="'required'"
              name="firstjobid"
              sizes="md"
              :class="errors.has('firstjobid') && 'is-invalid'"
            ></b-form-select>
            <b-form-invalid-feedback :state="errors.has('firstjobid')">
              {{ errors.first('firstjobid') }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group :label="`${$t('次選職位')}`" label-for="secondjobid" label-cols-sm="4" label-cols-lg="2">
            <b-form-select
              id="secondjobid"
              v-model="form.secondJobId"
              :options="jobsSec"
              disabled-field="notEnabled"
              name="secondjobid"
              :class="errors.has('secondjobid') && 'is-invalid'"
            ></b-form-select>
            <b-form-invalid-feedback :state="errors.has('secondjobid')">
              {{ errors.first('secondjobid') }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            :label="`${$t('期望薪金（澳門元＄）')}`"
            label-for="salary"
            label-cols-sm="4"
            label-cols-lg="2"
            class="_required"
          >
            <b-form-input
              :formatter="subnumberTen"
              id="salary"
              v-model="form.salary"
              type="number"
              v-validate="'required'"
              name="salary"
              placeholder="0"
              :class="errors.has('salary') && 'is-invalid'"
            ></b-form-input>
            <b-form-invalid-feedback :state="errors.has('salary')">
              {{ errors.first('salary') }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            :label="`${$t('离职时／现时月薪（澳门元＄）')}`"
            label-for="beforeSalary"
            label-cols-sm="4"
            label-cols-lg="2"
            class="_required"
          >
            <b-form-input
              :formatter="subnumberTen"
              id="beforesalary"
              v-model="form.beforeSalary"
              type="number"
              v-validate="'required'"
              name="beforesalary"
              placeholder="0"
              :class="errors.has('beforesalary') && 'is-invalid'"
            ></b-form-input>
            <b-form-invalid-feedback :state="errors.has('beforesalary')">
              {{ errors.first('beforesalary') }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            :label="`${$t('可任職日期')}`"
            label-for="servedate"
            label-cols-sm="4"
            label-cols-lg="2"
            class="_required"
          >
            <!--<b-form-datepicker
            id="servedate"
            v-validate="'required'"
            :placeholder="`${$t('選擇日期')}`"
            v-model="form.serveDate"
            class="mb-2"
            name="servedate"
            right
             :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
            :class="errors.has('servedate') && 'is-invalid'"
          />-->
            <DatePicker
              v-model="form.serveDate"
              v-validate="'required'"
              name="servedate"
              :class="['w-100', errors.has('servedate') && 'is-invalid']"
              :show-today="false"
              format="YYYY-MM-DD"
              :disabled-date="disabledDate"
              size="large"
              :placeholder="`${$t('選擇日期')}`"
              :defaultPickerValue="currentDay"
            />
            <b-form-invalid-feedback :state="errors.has('servedate')">
              {{ errors.first('servedate') }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-card>

        <h4 class="my-4 type-txt">{{ $t('個人資料') }}</h4>
        <b-card>
          <b-form-group
            :label="`${$t('中文姓名')}`"
            label-for="chinesename"
            label-cols-sm="4"
            label-cols-lg="2"
            class="_required"
          >
            <b-form-input
              maxlength="10"
              id="chinesename"
              v-model="form.nameZh"
              v-validate="'required|chinese'"
              name="chinesename"
              :class="errors.has('chinesename') && 'is-invalid'"
            ></b-form-input>
            <b-form-invalid-feedback :state="errors.has('chinesename')">
              {{ errors.first('chinesename') }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            :label="`${$t('外文姓名')}`"
            label-for="foreignname"
            label-cols-sm="4"
            label-cols-lg="2"
            class="_required"
          >
            <b-form-input
              maxlength="50"
              id="foreignname"
              v-model="form.nameEn"
              v-validate="'required|englist'"
              :placeholder="`${$t('按證件填寫')}`"
              name="foreignname"
              :class="errors.has('foreignname') && 'is-invalid'"
            ></b-form-input>
            <b-form-invalid-feedback :state="errors.has('foreignname')">
              {{ errors.first('foreignname') }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            :label="`${$t('性別')}`"
            label-for="gender"
            label-cols-sm="4"
            label-cols-lg="2"
            class="_required align-items-center"
          >
            <b-form-radio-group v-model="form.gender" id="gender" name="gender">
              <b-form-radio value="male" active="true">{{ $t('男') }}</b-form-radio>
              <b-form-radio value="female">{{ $t('女') }}</b-form-radio>
            </b-form-radio-group>
            <b-form-invalid-feedback :state="errors.has('gender')">
              {{ errors.first('gender') }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            :label="`${$t('出生日期')}`"
            label-for="birthday"
            label-cols-sm="4"
            label-cols-lg="2"
            class="_required"
          >
            <!-- <b-form-datepicker
            id="birthday"
            :placeholder="`${$t('選擇日期')}`"
            v-validate="'required'"
            v-model="form.birthday"
            class="mb-2"
            right
             name="birthday"
             :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
            :class="errors.has('birthday') && 'is-invalid'"
          />-->
            <DatePicker
              v-model="form.birthday"
              v-validate="'required'"
              name="birthday"
              :class="['w-100', errors.has('birthday') && 'is-invalid']"
              :show-today="false"
              format="YYYY-MM-DD"
              :disabled-date="disabledDate2"
              size="large"
              :placeholder="`${$t('選擇日期')}`"
              :defaultPickerValue="disableYear"
            />
            <b-form-invalid-feedback :state="errors.has('birthday')">
              {{ errors.first('birthday') }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group :label="`${$t('證件類別')}`" label-for="idtype" label-cols-sm="4" label-cols-lg="2">
            <b-form-select
              id="idtype"
              v-model="form.idType"
              :options="id_type_sel"
              disabled-field="notEnabled"
              name="idtype"
              :class="errors.has('idtype') && 'is-invalid'"
            ></b-form-select>
            <b-form-invalid-feedback :state="errors.has('idtype')">
              {{ errors.first('idtype') }}
            </b-form-invalid-feedback>

            <b-form-input
              v-if="form.idType == 4"
              id="idtypecontent"
              v-model="form.idTypeContent"
              v-validate="'required'"
              name="idtypecontent"
              :class="errors.has('idtypecontent') && 'is-invalid'"
            ></b-form-input>
            <b-form-invalid-feedback :state="errors.has('idtypecontent')">
              {{ errors.first('idtypecontent') }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group :label="`${$t('證件號碼')}`" label-for="idno" label-cols-sm="4" label-cols-lg="2">
            <!--必填 v-validate="{
                required: true,
                noTrim: true,
                idNumber: form.idType == 0 ? true : false
              }" -->
            <b-form-input
              id="idno"
              v-model="form.idNo"
              :placeholder="`${$t('格式：12345678無需輸入括號')}`"
              name="idno"
              :class="errors.has('idno') && 'is-invalid'"
            ></b-form-input>
            <b-form-invalid-feedback :state="errors.has('idno')">
              {{ errors.first('idno') }}
            </b-form-invalid-feedback>
          </b-form-group>

          <div class="d-flex justify-content-between flex-wrap">
            <b-form-group
              :label="`${$t('國籍')}`"
              label-for="country"
              label-cols-sm="8"
              label-cols-lg="4"
              class="col-12 col-md-6 p-0"
            >
              <b-form-select
                id="country"
                v-model="form.country"
                :options="countries"
                name="country"
                :class="errors.has('country') && 'is-invalid'"
              ></b-form-select>
              <b-form-invalid-feedback :state="errors.has('country')">
                {{ errors.first('country') }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              :label="`${$t('其他')}`"
              label-for="countryother"
              label-cols-sm="8"
              label-cols-lg="4"
              v-if="form.country == 'OT'"
              class="col-12 col-md-6 p-0 _required"
            >
              <b-form-input
                maxlength="11"
                id="countryother"
                v-model="form.countryOther"
                name="countryother"
                v-validate="'required'"
                :class="errors.has('countryother') && 'is-invalid'"
              ></b-form-input>
              <b-form-invalid-feedback :state="errors.has('countryother')">
                {{ errors.first('countryother') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>

          <div class="d-flex justify-content-between flex-wrap">
            <b-form-group
              :label="`${$t('聯絡電話')}`"
              label-for="region"
              label-cols-sm="8"
              label-cols-lg="4"
              class="col-12 col-md-6 p-0 _required"
            >
              <b-form-select
                id="region"
                v-model="form.region"
                :options="regionList"
                name="region"
                noCloseOnBackdrop="false"
                v-validate="'required'"
                :placeholder="`${$t('區號')}`"
                :class="errors.has('region') && 'is-invalid'"
              ></b-form-select>
              <b-form-invalid-feedback :state="errors.has('region')">
                {{ errors.first('region') }}
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              :label="`${$t('其他')}`"
              label-for="regionother"
              label-cols-sm="6"
              label-cols-lg="4"
              v-if="form.region == '-999'"
              class="col-12 col-md-3 px-0 px-md-2 _required"
            >
              <b-form-input
                maxlength="11"
                id="regionother"
                v-model="form.regionOther"
                name="regionother"
                v-validate="'required'"
                :class="errors.has('regionother') && 'is-invalid'"
              ></b-form-input>
              <b-form-invalid-feedback :state="errors.has('regionother')">
                {{ errors.first('regionother') }}
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              label-for="phone"
              :class="[form.region == '-999' ? 'col-12 col-md-3 p-0' : 'col-12 col-md-6 p-0']"
            >
              <b-form-input
                id="phone"
                v-model="form.phone"
                v-validate="'required|noTrim|position'"
                type="tel"
                :placeholder="`${$t('無需輸入區號')}`"
                name="phone"
                :class="errors.has('phone') && 'is-invalid'"
              ></b-form-input>
              <b-form-invalid-feedback :state="errors.has('phone')">
                {{ errors.first('phone') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>

          <b-form-group
            :label="`${$t('電郵地址')}`"
            label-for="email"
            label-cols-sm="4"
            label-cols-lg="2"
            class="_required"
          >
            <b-form-input
              maxlength="60"
              id="email"
              v-model="form.email"
              type="email"
              v-validate="'required|email'"
              name="email"
              :placeholder="`${$t('請輸入電郵地址')}`"
              :class="errors.has('email') && 'is-invalid'"
            ></b-form-input>
            <b-form-invalid-feedback :state="errors.has('email')">
              {{ errors.first('email') }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group :label="`${$t('居住地址')}`" label-for="address" label-cols-sm="4" label-cols-lg="2" class="">
            <b-form-input
              maxlength="60"
              id="address"
              v-model="form.address"
              name="address"
              :placeholder="`${$t('請輸入居住地址')}`"
              :class="errors.has('address') && 'is-invalid'"
            ></b-form-input>
            <b-form-invalid-feedback :state="errors.has('address')">
              {{ errors.first('address') }}
            </b-form-invalid-feedback>
          </b-form-group>
          <div class="d-flex justify-content-between">
            <b-form-group
              :label="`${$t('個人專長')}`"
              label-for="expertise"
              label-cols-sm="8"
              label-cols-lg="4"
              class="col-6 col-md-6 p-0"
            >
              <b-form-input
                maxlength="40"
                id="expertise"
                v-model="form.expertise"
                name="expertise"
                :class="errors.has('expertise') && 'is-invalid'"
              ></b-form-input>
              <b-form-invalid-feedback :state="errors.has('expertise')">
                {{ errors.first('expertise') }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              :label="`${$t('興趣')}`"
              label-for="interest"
              label-cols-sm="8"
              label-cols-lg="4"
              class="col-6 col-md-6 p-0"
            >
              <b-form-input
                maxlength="40"
                id="interest"
                v-model="form.interest"
                name="interest"
                :class="errors.has('interest') && 'is-invalid'"
              ></b-form-input>
              <b-form-invalid-feedback :state="errors.has('interest')">
                {{ errors.first('interest') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
        </b-card>
      </b-form>
    </div>
  </div>
</template>

<script>
import { DatePicker } from 'ant-design-vue'
import moment from 'moment'
export default {
  props: {
    content: {
      type: Object
    }
  },
  components: {
    DatePicker
  },
  data() {
    return {
      volume: '4',
      form: {
        firstJobId: '',
        secondJobId: '',
        salary: '',
        serveDate: '',
        beforeSalary: '',
        nameZh: '',
        nameEn: '',
        gender: 'male',
        birthday: null,
        idType: null,
        idNo: '',
        country: null,
        region: '',
        contact: '',
        email: '',
        address: '',
        expertise: '',
        interest: '',
        idTypeContent: ''
      },
      jobs: [
        {
          text: this.$t('請選擇'),
          value: null,
          notEnabled: true
        }
      ],
      jobsSec: [
        {
          text: this.$t('請選擇'),
          value: null
        }
      ],
      id_type_sel: [
        {
          text: this.$t('請選擇'),
          value: null,
          notEnabled: true
        },
        {
          text: this.$t('澳門永久居民身份證'),
          value: 0
        },
        {
          text: this.$t('澳門非永久居民身份證'),
          value: 1
        },
        {
          text: this.$t('香港居民身份證'),
          value: 2
        },
        {
          text: this.$t('中華人民共和國居民身份證'),
          value: 3
        },
        {
          text: this.$t('其他（請注明）'),
          value: 4
        }
      ],
      countries: [
        {
          text: this.$t('請選擇'),
          value: null,
          notEnabled: true
        },
        {
          text: '中國',
          value: 1
        }
      ],
      regionList: [],
      show: true,
      autoWrite: false
    }
  },
  watch: {},
  computed: {
    disableYear() {
      return moment().subtract(18, 'years')
    },
    currentDay() {
      return moment().subtract(1, 'days')
    }
  },
  methods: {
    subnumberTen(e) {
      return String(e).substring(0, 10)
    },
    disabledDate(current) {
      return current && current < moment().subtract(1, 'days')
    },
    disabledDate2(current) {
      return current > moment().subtract(18, 'years')
    },
    onSubmit() {
      return new Promise(resolve => {
        this.$validator.validate().then(validate => {
          if (validate) {
            resolve(true)
            const params = Object.assign({}, this.form)
            params.serveDate = params.serveDate ? moment(params.serveDate).format('YYYY-MM-DD') : null
            params.birthday = params.birthday ? moment(params.birthday).format('YYYY-MM-DD') : null
            // console.log(params,this.form);
            this.$store.dispatch('joinus/SET_One_Form', {
              data: params
            })
          } else {
            resolve(false)
            // this.$bvToast.toast(this.$t('請補全信息'), {
            //   toaster: 'b-toaster-top-center',
            //   autoHideDelay: 5000
            // })
            this.$emit('modalShow', this.$t('請補全信息'))
          }
        })
      })
    },
    autoWriteHandle() {
      this.form = {
        address: '123',
        birthday: moment('2001-06-06'),
        nameZh: '張三',
        nameEn: 'three',
        description: '123',
        email: 'gcsphoto8@gmail.com',
        expertise: '123',
        firstJobId: 2,
        gender: 'male',
        hasIntroducer: false,
        idNo: '12312213123',
        idType: '1',
        interest: '123',
        region: '+853',
        phone: '66666666',
        salary: '123',
        serveDate: moment('2021-06-06'),
        beforeSalary: '11111'
        // country: 'CN'
      }
    }
  },
  beforeCreate() {},
  created() {},
  mounted() {
    // console.log(moment('2020-09-04'), this.content)
    this.autoWrite = JSON.parse(sessionStorage.getItem('setting'))['auto-write']
    console.log(this.autoWrite)
    if (this.content) {
      this.content.jobs.forEach(e => {
        this.jobs.push({
          value: e.id,
          text: e.title
        })
        this.jobsSec.push({
          value: e.id,
          text: e.title
        })
      })

      this.countries = this.content.nation
      this.regionList = this.content.region
      // console.log(123, this.$route.query)
      if (this.$route.query.jobId) {
        this.form.firstJobId = this.$route.query.jobId
      }
    }
    // this.jobs.push(this.content.title)
  }
}
</script>
