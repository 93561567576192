<template>
  <div class="upload">
    <input ref="upload_input" type="file" class="d-none" :accept="accept" @change="handleChange($event)" />
    <b-overlay :show="loading" rounded="sm" v-if="type == 0">
      <b-form-input v-model="file" :placeholder="placeholder" readonly @click="handleClick" />
    </b-overlay>
    <b-overlay :show="loading" rounded="sm" v-if="type == 1">
      <a rel="noopener noreferrer" v-if="file" target="_blank" class="mr-3">{{ fileName }}</a>
      <!-- <a rel="noopener noreferrer" :href="file" v-if="file" target="_blank" class="mr-3">{{ fileName }}</a> -->
      <b-button class="btn" @click="handleClick">{{ $t('上傳') }}</b-button>
    </b-overlay>
    <b-overlay :show="loading" rounded="sm" v-else>
      <a rel="noopener noreferrer" v-if="file" target="_blank" class="mr-3">{{ fileName }}</a>
      <b-button class="btn" @click="handleClick">{{ $t('上傳') }}</b-button>
      <!-- <div class="photo" @click="handleClick">
        <i>{{ placeholder }}</i>
         <img v-if="file" :src="file" :alt="file" />
      </div> -->
      <!-- <b-form-input class="photo" v-model="file" :placeholder="placeholder" readonly @click="handleClick" /> -->
    </b-overlay>
  </div>
</template>

<script>
import { TakeUpload } from '@/api/join_us'
import dataURItoBlob from '@/utils'
import axios from 'axios'
export default {
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    accept: {
      type: String,
      default: '.jpg,.jpeg, .pdf, .doc ,.docx'
    },
    value: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    type: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      loading: false,
      file: null,
      fileName: null,
      msg: `${this.$t('TEXT_UPLOAD_LIMMT')}`
    }
  },
  watch: {
    // value: {
    //     handler(n) {
    //         this.file = n
    //     },
    //     deep: true,
    //     immediate: true
    // }
  },
  methods: {
    handleClick() {
      this.$refs.upload_input.value = null
      this.$refs.upload_input.click()
    },
    hideModal(el) {
      console.log(el)
      this.$bvModal.hide(el)
    },
    handleChange(event) {
      var formData = new FormData()
      var file = event.target.files[0]
      formData.append('file', file)
      formData.append('field', this.name)
      this.loading = true
      const _this = this
      axios.defaults.headers.post['Content-Type'] = 'multipart/form-data'
      if (file.size <= 2000000) {
        // 接收上传文件的后台地址
        var FileController = '/api/v1.0/common/upload'
        // FormData 对象
        var xhr = new XMLHttpRequest()
        xhr.open('post', FileController, true)
        // xhr.onload = function (e) {
        //   // console.log('formData: %o', res)
        //   // const path = res.data.fileHash
        //   // this.file = res.data.file
        //   // this.$emit('input', path)
        //   // _this.loading = false
        //   if (this.status == 200 || this.status == 304) {
        //     // const path = this.responseText.data.fileHash
        //     // this.file = this.responseText.data.file
        //     // this.$emit('input', path)
        //     // _this.loading = false
        //   }
        // }
        // xhr.setRequestHeader('Content-Type', 'multipart/form-data')
        console.log(1233132, file)
        let time = 0
        xhr.onreadystatechange = function() {
          if (xhr.readyState == XMLHttpRequest.DONE && xhr.status == 200) {
            var responseText = xhr.responseText //返回结果
            var obj = JSON.parse(responseText)
            if (obj.code == 0) {
              const path = obj.data.fileHash
              _this.file = obj.data.fileHash
              _this.fileName = file.name
              _this.$emit('input', path)
              _this.loading = false
            } else {
              // _this.$bvToast.toast(`${obj.message}`, {
              //   toaster: 'b-toaster-top-center',
              //   autoHideDelay: 5000
              // })
              _this.msg = obj.message
              _this.$emit('popModal', obj.message)

              _this.loading = false
            }
            // console.log(123, obj.data.fileHash, obj.data.file)
          }
        }
        xhr.send(formData)
        // TakeUpload(formData)
        //   .then(res => {
        //     console.log('formData: %o', file.size, res.data.file)
        //     const path = res.data.fileHash
        //     this.file = res.data.file
        //     this.$emit('input', path)
        //   })
        //   .catch(err => {
        //     this.$bvToast.toast(`${err.message}`, {
        //       autoHideDelay: 5000
        //     })
        //   })
        //   .finally(() => {
        //     this.loading = false
        //   })
      } else {
        // this.$bvToast.toast(this.$t('TEXT_UPLOAD_LIMMT'), {
        //   toaster: 'b-toaster-top-center',
        //   autoHideDelay: 5000
        // })

        this.msg = this.$t('TEXT_UPLOAD_LIMMT')
        _this.$emit('popModal', this.$t('TEXT_UPLOAD_LIMMT'))
        // this.$bvModal.show('modal-msg-tips')
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.upload.is-invalid {
  .form-control {
    border-color: #dc3545;
    padding-right: 0.75rem;
  }
}
</style>
