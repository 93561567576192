<template>
  <div>
    <b-container class="mt-4" v-if="isMounted">
      <b-form ref="stepForm" v-if="show" class="my-5">
        <div @click="autoWriteHandle" v-show="autoWrite == 'true' ? true : false">{{ $t('自動填寫') }}</div>
        <h4 class="my-4 type-txt">{{ $t('文件上傳') }}</h4>
        <b-card>
          <b-form-group
            :label="`${$t('請上傳您的個人簡歷')}`"
            label-for="resumeFile"
            label-cols-sm="4"
            label-cols-lg="2"
            :description="`${$t('支援的上傳類型')}: pdf, jpg, jpeg`"
          >
            <InputFile
              id="cover"
              v-model="form.resumeFile"
              name="cover"
              :placeholder="`${$t('請上傳您的個人簡歷(需少於50M)')}`"
              :accept="'.pdf,.jpg,.jpeg,.PDF,.JPG,.JPEG'"
              :class="errors.has('cover') && 'is-invalid'"
              :type="1"
              @popModal="popModal"
            />
            <b-form-invalid-feedback :state="errors.has('cover')">
              {{ errors.first('cover') }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            :label="`${$t('請上傳您的學歷證明')}`"
            label-for="diplomaFile"
            label-cols-sm="4"
            label-cols-lg="2"
            :description="
              `${$t('支援的上傳類型')}: pdf, jpg, jpeg ; ${$t('如學位畢業證書丶成績表或其他專業資格證明等')}`
            "
          >
            <Upload
              name="diplomaFile"
              :file-list="fileList"
              action="#"
              v-model="form.diplomaFile"
              @change="handleChange"
              :remove="handleRemove"
              :class="errors.has('diplomaFile') && 'is-invalid'"
              accept=".pdf,.jpg,.jpeg,.PDF,.JPG,.JPEG"
            >
              <b-button v-if="fileList.length < 10"> <i type="upload" /> {{ $t('上傳') }} </b-button>
            </Upload>
            <b-form-invalid-feedback :state="errors.has('diplomaFile')">
              {{ errors.first('diplomaFile') }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            :label="`${$t('請上傳您的個人近照')}`"
            label-for="photo"
            label-cols-sm="4"
            label-cols-lg="2"
            :description="`${$t('支援jpg, jpeg圆片格式')}`"
          >
            <InputFile
              id="photo"
              v-model="form.photo"
              name="photo"
              :type="2"
              :accept="'.jpg,.jpeg,.JPG,.JPEG'"
              :placeholder="`${$t('請上傳您的個人近照')}`"
              :class="errors.has('photo') && 'is-invalid'"
              @popModal="popModal"
            />
            <b-form-invalid-feedback :state="errors.has('photo')">
              {{ errors.first('photo') }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            :label="`${$t('申請人聲明')}`"
            label-for="statement"
            label-cols-sm="4"
            label-cols-lg="2"
            class="_required"
          >
            <b-form-checkbox-group
              v-model="form.statement"
              id="statement"
              v-validate="'required'"
              name="statement"
              :class="[errors.has('statement') && 'is-invalid', 'd-flex flex-column']"
            >
              <b-form-checkbox value="1" class="my-2">{{ $t('formStatement1') }}</b-form-checkbox>
              <b-form-checkbox value="2" class="my-2">{{ $t('formStatement2') }}</b-form-checkbox>
              <b-form-checkbox value="3" class="my-2">{{ $t('formStatement3') }}</b-form-checkbox>
            </b-form-checkbox-group>
            <b-form-invalid-feedback :state="errors.has('statement')">
              {{ errors.first('statement') }}
            </b-form-invalid-feedback>
          </b-form-group>

          <p class="tips">
            {{ $t('formStatement4') }}<br />
            {{ $t('formStatement5') }}
          </p>
          <b-form-group
            :label="`${$t('請輸入驗證碼')}`"
            label-for="vercode"
            label-cols-sm="4"
            label-cols-lg="2"
            class="align-items-center _required"
          >
            <div class="d-flex flex-row my-4 ">
              <div class="col-6">
                <b-form-input
                  maxlength="6"
                  id="vercode"
                  v-model="form.verCode"
                  v-validate="'required'"
                  name="vercode"
                  :class="[, errors.has('vercode') && 'is-invalid']"
                ></b-form-input>
                <b-form-invalid-feedback :state="errors.has('vercode')">
                  {{ errors.first('vercode') }}
                </b-form-invalid-feedback>
              </div>
              <div class="coderight" @click="refreshCode" v-if="isMounted">
                <img :src="identifyCode" style="width:150px" />
                <!-- <SIdentify :identifyCode="identifyCode" v-if="code"></SIdentify> -->
              </div>
            </div>
          </b-form-group>
        </b-card>
      </b-form>
    </b-container>
    <b-modal id="modal-msg-tips" ref="modalinput" centered hide-footer hide-header>
      <div class="text-center msg">{{ msg }}</div>
      <div class="text-center">
        <b-button class="w-25 mt-4" @click.stop="hideModal('modal-msg-tips')">{{ $t('返回') }}</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { IdentifyCode } from '@/api/join_us'
import { Upload } from 'ant-design-vue'
import InputFile from '@/components/InputFile'
import axios from 'axios'
// import SIdentify from '@/components/identify'
export default {
  components: {
    InputFile,
    // SIdentify,
    Upload
  },
  props: {
    value: {
      type: Boolean
    }
  },
  data() {
    return {
      isMounted: false,
      form: {
        // resumeFile: '9e4d10fe430444e6aec1e342f078c804.jpg',
        resumeFile: '',
        diplomaFile: [],
        photo: '',
        statement: []
        // schoolName: ''
        // verCode: ''
      },
      loading: false,
      show: true,
      autoWrite: false,
      code: false,
      resetData: null,
      uCode: 'Tai8a8',
      identifyCode: '',
      identifyCodes: 'ABCDEFGHIJKLMNOPQRSTUVWYZabcdefghijklmnopqrstuvwyz1234567890',
      file: '',
      fileName: '',
      fileList: [],
      msg: ''
    }
  },
  watch: {
    value(o, n) {
      if (o) {
        this.$validator.errors.add({
          field: 'vercode',
          msg: this.$t('驗證碼錯誤')
        })
        this.$emit('update:value', false)
      }
    }
  },
  computed: {},
  methods: {
    async getBase64FromUrl(url) {
      // const data = await fetch(url)
      // const blob = await data.blob()
      // console.log(111, blob)
      const config = { responseType: 'blob' }
      let blob = null
      await axios.get(url, config).then(response => {
        // console.log(response.data)
        blob = response.data
      })
      return new Promise(resolve => {
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onloadend = () => {
          const base64data = reader.result
          resolve(base64data)
        }
      })
    },
    reloadCaptcha() {
      this.getBase64FromUrl('/api/v1/common/captcha?n=' + new Date().getTime()).then(e => {
        this.identifyCode = e
      })
    },
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min)
    },
    refreshCode() {
      // this.identifyCode = ''
      // this.makeCode(this.identifyCodes, 6)
      this.reloadCaptcha()
    },
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode += this.identifyCodes[this.randomNum(0, this.identifyCodes.length)]
      }
    },
    onSubmit() {
      // console.log(99, this.identifyCode, this.form.verCode)
      return new Promise(resolve => {
        this.$validator.validate().then(validate => {
          console.log(123, this.errors)
          if (validate) {
            if (this.form.statement.length < 3) {
              this.errors.add({
                field: 'statement',
                msg: this.$t('閣下尚未作出聲明')
              })
            } else {
              // if (this.identifyCode == this.form.verCode || this.form.verCode == this.uCode) {
              this.$store.dispatch('joinus/SET_Fourth_Form', {
                data: this.form
                // identifyCode: this.form.verCode
              })
              // this.$store.dispatch('page/setFourthForm', { data: this.form })
              resolve(true)
              // } else {
              //   this.errors.add({
              //     field: 'vercode',
              //     msg: '請輸入正確的驗證碼'
              //   })
              // }
            }
          } else {
            resolve(false)
            // this.$bvToast.toast(this.$t('請補全信息'), {
            //   toaster: 'b-toaster-top-center',
            //   autoHideDelay: 5000
            // })
            this.$emit('modalShow', this.$t('請補全信息'))
          }
        })
      })
    },
    handleChange(event) {
      let fileList = [...event.fileList]
      fileList = fileList.slice(-10)
      fileList = fileList.map(file => {
        if (file.response) {
          file.url = file.response.url
          var formData = new FormData()
          var file = event.file.originFileObj
          formData.append('file', file)
          formData.append('field', event.file.name)
          this.loading = true
          const _this = this
          axios.defaults.headers.post['Content-Type'] = 'multipart/form-data'
          if (file.size <= 2000000) {
            var FileController = '/api/v1.0/common/upload'
            var xhr = new XMLHttpRequest()
            xhr.open('post', FileController, true)
            xhr.onreadystatechange = function() {
              if (xhr.readyState == XMLHttpRequest.DONE && xhr.status == 200) {
                var responseText = xhr.responseText //返回结果
                var obj = JSON.parse(responseText)
                if (obj.code == 0) {
                  _this.form.diplomaFile.push(obj.data.fileHash)
                } else {
                  setTimeout(e => {
                    fileList.pop()
                  }, 500)
                  _this.$bvToast.toast(`${obj.message}`, {
                    toaster: 'b-toaster-top-center',
                    autoHideDelay: 5000
                  })
                }
              }
            }
            xhr.send(formData)
          } else {
            setTimeout(e => {
              fileList.pop()
            }, 500)
            // this.$bvToast.toast(`請選擇正確類型及大小`, {
            //   toaster: 'b-toaster-top-center',
            //   autoHideDelay: 5000
            // })
            this.$emit('modalShow', this.$t('請選擇正確類型及大小'))
          }
        }

        return file
      })

      this.fileList = fileList
      console.log(this.fileList)
    },
    handleRemove(file) {
      const index = this.fileList.indexOf(file)
      const newFileList = this.fileList.slice()
      this.form.diplomaFile.splice(index, 1)
    },
    popModal(e) {
      this.msg = e
      this.$bvModal.show('modal-msg-tips')
    },
    hideModal(el) {
      console.log(el)
      this.$bvModal.hide(el)
    },
    autoWriteHandle() {
      this.form = {
        resumeFile: '/api/v1.0/common/tmpfile/524a34a15db0420f88eed6115985c483.jpg',
        diplomaFile: '/api/v1.0/common/tmpfile/524a34a15db0420f88eed6115985c483.jpg',
        photo: '/api/v1.0/common/tmpfile/524a34a15db0420f88eed6115985c483.jpg',
        statement: [1, 1, 1]
      }
    }
  },
  beforeCreate() {},
  created() {},
  mounted() {
    this.autoWrite = JSON.parse(sessionStorage.getItem('setting'))['auto-write']
    this.code = true
    this.identifyCode = ''
    // this.makeCode(this.identifyCodes, 6)
    this.reloadCaptcha()
    this.isMounted = true
  }
}
</script>
