<template>
  <div>
    <b-container class="mt-4">
      <b-form ref="stepForm" v-if="show" class="my-5">
        <!-- <div @click="autoWriteHandle">{{ $t('自動填寫') }}</div> -->
        <div @click="autoWriteHandle" v-show="autoWrite == 'true' ? true : false">{{ $t('自動填寫') }}</div>
        <h4 class="my-4 type-txt">{{ $t('教育程度') }}</h4>
        <b-card>
          <b-form-group
            :label="$t('最高學歷')"
            label-for="qualification"
            label-cols-sm="4"
            label-cols-lg="2"
            class="_required"
          >
            <b-form-select
              id="qualification"
              v-model="form.qualification"
              :options="education"
              v-validate="'required'"
              name="qualification"
              disabled-field="notEnabled"
              :class="errors.has('qualification') && 'is-invalid'"
            ></b-form-select>
            <b-form-invalid-feedback :state="errors.has('qualification')">
              {{ errors.first('qualification') }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            :label="$t('院校名稱')"
            label-for="schoolname"
            label-cols-sm="4"
            label-cols-lg="2"
            class="_required"
          >
            <b-form-input
              maxlength="30"
              id="schoolname"
              v-model="form.schoolName"
              name="schoolname"
              v-validate="'required'"
              :class="errors.has('schoolname') && 'is-invalid'"
            ></b-form-input>
            <b-form-invalid-feedback :state="errors.has('schoolname')">
              {{ errors.first('schoolname') }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            :label="$t('主修科目/專業')"
            label-for="profession"
            label-cols-sm="4"
            label-cols-lg="2"
            class="_required"
          >
            <b-form-input
              maxlength="30"
              id="profession"
              v-model="form.profession"
              name="profession"
              v-validate="'required'"
              :class="errors.has('profession') && 'is-invalid'"
            ></b-form-input>
            <b-form-invalid-feedback :state="errors.has('profession')">
              {{ errors.first('profession') }}
            </b-form-invalid-feedback>
          </b-form-group>

          <div class="d-flex flex-wrap justify-content-between">
            <b-form-group
              class="px-0 pr-md-1 col-12 col-md-6 _required"
              :label="$t('開始年月')"
              label-for="schooltimestart"
              label-cols-sm="8"
              label-cols-lg="4"
            >
              <!--<b-form-datepicker
                id="schooltimestart"
                :placeholder="$t('選擇日期')"
                v-model="form.schoolTimeStart"
                class="mb-2"
                right
                name="schooltimestart"
                v-validate="'required'"
                :class="errors.has('schooltimestart') && 'is-invalid'"
                :date-format-options="{
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit'
                }"
              />-->
              <!-- <MonthPicker
                v-model="form.schoolTimeStart"
                v-validate="'required'"
                name="schooltimestart"
                :class="['w-100', errors.has('schooltimestart') && 'is-invalid']"
                :show-today="false"
                format="YYYY-MM"
                :disabled-date="disabledDate"
                size="large"
                :placeholder="`${$t('選擇日期')}`"
                :defaultPickerValue="currentDay"
              /> -->
              <b-form-input
                v-validate="'required'"
                maxlength="7"
                name="schooltimestart"
                v-model="form.schoolTimeStart"
                :placeholder="$t('格式：(年份 YYYY/月份 MM)')"
                :class="['w-100', errors.has('schooltimestart') && 'is-invalid']"
              ></b-form-input>
              <b-form-invalid-feedback :state="errors.has('schooltimestart')">
                {{ errors.first('schooltimestart') }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              class="px-0 pl-md-1 col-12 col-md-6 _required"
              :label="$t('結束年月')"
              label-for="schooltimeend"
              label-cols-sm="8"
              label-cols-lg="4"
            >
              <!--<b-form-datepicker
                id="schooltimeend"
                :placeholder="$t('選擇日期')"
                v-model="form.schoolTimeEnd"
                class="mb-2"
                right
                name="schooltimeend"
                v-validate="'required'"
                :class="errors.has('schooltimeend') && 'is-invalid'"
                :date-format-options="{
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit'
                }"
              />-->
              <!-- <MonthPicker
                v-model="form.schoolTimeEnd"
                v-validate="'required'"
                name="schooltimeend"
                :class="['w-100', errors.has('schooltimeend') && 'is-invalid']"
                :show-today="false"
                format="YYYY-MM"
                size="large"
                :placeholder="`${$t('選擇日期')}`"
                :defaultPickerValue="currentDay"
              /> -->
              <b-form-input
                v-validate="'required'"
                maxlength="7"
                name="schooltimeend"
                v-model="form.schoolTimeEnd"
                :placeholder="$t('格式：(年份 YYYY/月份 MM)')"
                :class="['w-100', errors.has('schooltimeend') && 'is-invalid']"
              ></b-form-input>
              <b-form-invalid-feedback :state="errors.has('schooltimeend')">
                {{ errors.first('schooltimeend') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>

          <b-form-group :label="$t('成績(GPA)/平均分')" label-for="achievements" label-cols-sm="4" label-cols-lg="2">
            <b-form-input
              maxlength="6"
              id="achievements"
              v-model="form.achievements"
              name="achievements"
              :class="errors.has('achievements') && 'is-invalid'"
            ></b-form-input>
            <b-form-invalid-feedback :state="errors.has('achievements')">
              {{ errors.first('achievements') }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            :label="$t('是否獲獎學金或其他獎勵')"
            label-for="scholarship"
            label-cols-sm="4"
            label-cols-lg="2"
          >
            <b-form-select
              id="scholarship"
              v-model="form.scholarship"
              :options="yesNo"
              name="scholarship"
              disabled-field="notEnabled"
              :class="errors.has('scholarship') && 'is-invalid'"
            ></b-form-select>
            <b-form-invalid-feedback :state="errors.has('scholarship')">
              {{ errors.first('scholarship') }}
            </b-form-invalid-feedback>
            <b-form-input
              maxlength="40"
              id="scholarshipcontent"
              v-if="form.scholarship == 1"
              v-model="form.scholarshipcontent"
              :options="yesNo"
              name="scholarshipcontent"
              v-validate="'required'"
              :class="errors.has('scholarshipcontent') && 'is-invalid'"
            ></b-form-input>
            <b-form-invalid-feedback :state="errors.has('scholarshipcontent')">
              {{ errors.first('scholarshipcontent') }}
            </b-form-invalid-feedback>
          </b-form-group>

          <hr class="my-5" />

          <b-form-group :label="$t('其他學歷')" label-for="otherqualification" label-cols-sm="4" label-cols-lg="2">
            <b-form-select
              id="otherqualification"
              v-model="form.otherQualification"
              :options="education"
            ></b-form-select>
          </b-form-group>

          <b-form-group :label="$t('院校名稱')" label-for="otherschoolname" label-cols-sm="4" label-cols-lg="2">
            <b-form-input maxlength="30" id="otherschoolname" v-model="form.otherSchoolName"></b-form-input>
          </b-form-group>

          <b-form-group :label="$t('主修科目')" label-for="otherprofession" label-cols-sm="4" label-cols-lg="2">
            <b-form-input maxlength="30" id="otherprofession" v-model="form.otherProfession"></b-form-input>
          </b-form-group>

          <div class="d-flex flex-wrap justify-content-between">
            <b-form-group
              class="px-0 pr-md-1 col-12 col-md-6"
              :label="$t('開始年月')"
              label-for="otherschooltimestart"
              label-cols-sm="8"
              label-cols-lg="4"
            >
              <!--<b-form-datepicker
                id="otherschooltimestart"
                :placeholder="$t('選擇日期')"
                v-model="form.otherSchoolTimeStart"
                class="mb-2"
                right
                :date-format-options="{
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit'
                }"
              />-->
              <MonthPicker
                v-model="form.otherSchoolTimeStart"
                :class="['w-100']"
                :show-today="false"
                format="YYYY-MM"
                :disabled-date="disabledDate"
                size="large"
                :placeholder="`${$t('選擇日期')}`"
                :defaultPickerValue="currentDay"
              />
            </b-form-group>

            <b-form-group
              class="px-0 pl-md-1 col-12 col-md-6"
              :label="$t('結束年月')"
              label-for="otherschooltimeend"
              label-cols-sm="8"
              label-cols-lg="4"
            >
              <!--<b-form-datepicker
                id="otherschooltimeend"
                :placeholder="$t('選擇日期')"
                v-model="form.otherSchoolTimeEnd"
                right
                class="mb-2"
                :date-format-options="{
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit'
                }"
              />-->
              <MonthPicker
                v-model="form.otherSchoolTimeEnd"
                :class="['w-100']"
                :show-today="false"
                format="YYYY-MM"
                :disabled-date="disabledDate"
                size="large"
                :placeholder="`${$t('選擇日期')}`"
                :defaultPickerValue="currentDay"
              />
            </b-form-group>
          </div>
          <hr class="my-5" />
          <b-form-group
            :label="$t('中學就讀學校')"
            label-for="highschoolname"
            label-cols-sm="4"
            label-cols-lg="2"
            class="_required"
          >
            <b-form-input
              maxlength="30"
              id="highschoolname"
              v-model="form.highSchoolName"
              name="highschoolname"
              v-validate="'required'"
              :class="errors.has('highschoolname') && 'is-invalid'"
            ></b-form-input>
            <b-form-invalid-feedback :state="errors.has('highschoolname')">
              {{ errors.first('highschoolname') }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            :label="$t('畢業年月')"
            label-for="highschoolgraduation"
            label-cols-sm="4"
            label-cols-lg="2"
            class="_required"
          >
            <!--<b-form-datepicker
              id="highschoolgraduation"
              :placeholder="$t('選擇日期')"
              v-model="form.highSchoolGraduation"
              right
              class="mb-2"
              name="highschoolgraduation"
              v-validate="'required'"
              :class="errors.has('highschoolgraduation') && 'is-invalid'"
              :date-format-options="{
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
              }"
            />-->
            <MonthPicker
              v-model="form.highSchoolGraduation"
              v-validate="'required'"
              name="highschoolgraduation"
              :class="['w-100', errors.has('highschoolgraduation') && 'is-invalid']"
              :show-today="false"
              format="YYYY-MM"
              :disabled-date="disabledMonth"
              size="large"
              :placeholder="`${$t('選擇日期')}`"
              :defaultPickerValue="currentDay"
            />
            <b-form-invalid-feedback :state="errors.has('highschoolgraduation')">
              {{ errors.first('highschoolgraduation') }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            :label="$t('成績等級/平均分')"
            label-for="highschoolachievements"
            label-cols-sm="4"
            label-cols-lg="2"
          >
            <b-form-input
              maxlength="6"
              id="highschoolachievements"
              v-model="form.highSchoolAchievements"
              name="highschoolachievements"
              :class="errors.has('highschoolachievements') && 'is-invalid'"
            ></b-form-input>
            <b-form-invalid-feedback :state="errors.has('highschoolachievements')">
              {{ errors.first('highschoolachievements') }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-card>
        <h4 class="my-4 type-txt">
          {{ $t('工作經驗') }}<em>({{ $t('先填寫現職或最近工作') }})</em>
        </h4>
        <b-card>
          <div v-for="(worklist, key) in form.workList" :key="key">
            <b-form-group :label="`${$t('機構名稱')}`" label-for="companyName" label-cols-sm="4" label-cols-lg="2">
              <b-form-input maxlength="30" v-model="worklist.companyName" id="companyName"></b-form-input>
            </b-form-group>

            <b-form-group :label="`${$t('職位')}`" label-for="companyposition" label-cols-sm="4" label-cols-lg="2">
              <b-form-input maxlength="30" id="companyposition" v-model="worklist.companyPosition"></b-form-input>
            </b-form-group>

            <b-form-group :label="`${$t('任職日期')}`" label-cols-sm="4" label-cols-lg="2" class="blue">
              <div class="d-flex flex-wrap align-items-center">
                <b-form-group label-for="officedatestart" class="mb-0 px-0 pr-md-1 col-12 col-md-5">
                  <b-form-input
                    maxlength="7"
                    id="officedatestart"
                    v-model="worklist.officeDateStart"
                    :placeholder="$t('格式：(年份 YYYY/月份 MM)')"
                  ></b-form-input>
                </b-form-group>
                <div class="col-12 col-md-2 text-center blue">{{ $t('至') }}</div>
                <b-form-group label-for="officedateend" class="mb-0 px-0 pl-md-1 col-12 col-md-5">
                  <b-form-input
                    maxlength="18"
                    id="officedateend"
                    v-model="worklist.officeDateEnd"
                    :placeholder="`${$t('格式：(年份 YYYY/月份 MM)')} ${$t('或現職')}`"
                  ></b-form-input>
                </b-form-group>
              </div>
            </b-form-group>
            <b-form-group :label="`${$t('工作描述')}`" label-for="description" label-cols-sm="4" label-cols-lg="2">
              <b-form-textarea
                maxlength="60"
                id="description"
                v-model="worklist.description"
                name="content"
                :placeholder="$t('請註明詳細工作內容')"
                :class="errors.has('content') && 'is-invalid'"
                no-resize
                rows="6"
                no-auto-shrink
              ></b-form-textarea>
              <b-form-invalid-feedback :state="errors.has('content')">
                {{ errors.first('content') }}
              </b-form-invalid-feedback>
            </b-form-group>
            <!-- <b-button style="" v-if="form.workList.length > 1" @click="delListForm(worklist)">-</b-button> -->
            <hr class="my-5" />
          </div>
          <!-- 
          <div>
            <b-form-group :label="`${$t('機構名稱')}:`" label-for="companyName2" label-cols-sm="4" label-cols-lg="2">
              <b-form-input v-model="form.workList[1].companyName" id="companyName2"></b-form-input>
            </b-form-group>

            <b-form-group :label="`${$t('職位')}:`" label-for="companyposition2" label-cols-sm="4" label-cols-lg="2">
              <b-form-input id="companyposition2" v-model="form.workList[1].companyPosition"></b-form-input>
            </b-form-group>

            <b-form-group :label="`${$t('任職日期')}:`" label-cols-sm="4" label-cols-lg="2" class="blue">
              <div class="d-flex flex-wrap align-items-center">
                <b-form-group label-for="officedatestart2" class="mb-0 px-0 pr-md-1 col-12 col-md-5">
                  <b-form-input
                    id="officedatestart2"
                    v-model="form.workList[1].officeDateStart"
                    :placeholder="$t('格式:年份/月份')"
                  ></b-form-input>
                </b-form-group>
                <div class="col-12 col-md-2 text-right blue">{{ $t('至') }}</div>
                <b-form-group label-for="officedateend2" class="mb-0 px-0 pl-md-1 col-12 col-md-5">
                  <b-form-input
                    id="officedateend2"
                    v-model="form.workList[1].officeDateEnd"
                    :placeholder="`${$t('格式:年份/月份')}·${$t('或現職')}`"
                  ></b-form-input>
                </b-form-group>
              </div>
            </b-form-group>
            <b-form-group :label="`${$t('工作描迹')}:`" label-for="description2" label-cols-sm="4" label-cols-lg="2">
              <b-form-textarea
                id="description2"
                v-model="form.workList[1].description"
                name="content"
                :placeholder="$t('請註明詳細工作內容')"
                :class="errors.has('content') && 'is-invalid'"
                no-resize
                rows="6"
                no-auto-shrink
              ></b-form-textarea>
              <b-form-invalid-feedback :state="errors.has('content')">
                {{ errors.first('content') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div> -->
          <b-button
            class="m-auto d-block"
            style="border:none; transition:none;box-shadow:none;"
            :pressed="true"
            @click="addListForm"
            >{{ $t('新增工作經驗') }}</b-button
          >
        </b-card>
      </b-form>
    </b-container>
  </div>
</template>

<script>
// import { fetchContent } from '@/api/page'
import { DatePicker } from 'ant-design-vue'
import moment from 'moment'
export default {
  components: {
    MonthPicker: DatePicker.MonthPicker
  },
  data() {
    return {
      form: {
        qualification: '',
        schoolName: '',
        profession: '',
        schoolTimeStart: '',
        schoolTimeEnd: '',
        achievements: '',
        scholarship: '',
        scholarshipcontent: '',
        otherQualification: '',
        otherSchoolName: '',
        otherProfession: '',
        otherSchoolTimeStart: '',
        otherSchoolTimeEnd: '',
        highSchoolName: '',
        highSchoolGraduation: '',
        highSchoolAchievements: '',
        workList: [
          {
            companyName: '',
            companyPosition: '',
            officeDateStart: '',
            officeDateEnd: '',
            description: ''
          }
        ]
      },
      foods: [
        {
          text: this.$t('請選擇'),
          value: null,
          notEnabled: true
        },
        '1',
        '2',
        '3',
        '4'
      ],
      yesNo: [
        {
          text: this.$t('請選擇'),
          value: null,
          notEnabled: true
        },
        {
          text: this.$t('是（請注明）'),
          value: 1
        },
        {
          text: this.$t('否'),
          value: 0
        }
      ],
      education: [
        {
          text: this.$t('請選擇'),
          value: null,
          notEnabled: true
        },
        {
          text: this.$t('博士'),
          value: 5
        },
        {
          text: this.$t('碩士'),
          value: 4
        },
        {
          text: this.$t('學士'),
          value: 3
        },
        {
          text: this.$t('大專'),
          value: 6
        },
        {
          text: this.$t('中學'),
          value: 2
        },
        {
          text: this.$t('其他'),
          value: 1
        }
      ],
      show: true,
      resetData: null,
      autoWrite: false
    }
  },
  computed: {
    currentDay() {
      return moment().subtract(0, 'days')
    },
    disableYear() {
      return moment().subtract(16, 'years')
    }
  },
  methods: {
    disabledDate(current) {
      return current && current > moment().subtract(1, 'days')
    },
    disabledMonth(current) {
      return current && current > moment().subtract(0, 'months')
    },
    disabledDate2(current) {
      return current > moment().subtract(16, 'years')
    },
    addListForm() {
      this.form.workList.push({
        companyName: '',
        companyPosition: '',
        officeDateStart: '',
        officeDateEnd: '',
        description: ''
      })
    },
    delListForm(item) {
      const index = this.form.workList.indexOf(item)
      if (index !== -1 && this.form.workList.length > 1) {
        this.form.workList.splice(index, 1)
      }
    },
    onSubmit() {
      return new Promise(resolve => {
        this.$validator.validate().then(validate => {
          if (validate) {
            resolve(true)
            const params = Object.assign({}, this.form)
            params.schoolTimeStart = params.schoolTimeStart ? moment(params.schoolTimeStart).format('YYYY-MM') : null
            params.schoolTimeEnd = params.schoolTimeEnd ? moment(params.schoolTimeEnd).format('YYYY-MM') : null
            params.highSchoolGraduation = params.highSchoolGraduation
              ? moment(params.highSchoolGraduation).format('YYYY-MM')
              : null
            params.otherSchoolTimeStart = params.otherSchoolTimeStart
              ? moment(params.otherSchoolTimeStart).format('YYYY-MM')
              : null
            params.otherSchoolTimeEnd = params.otherSchoolTimeEnd
              ? moment(params.otherSchoolTimeEnd).format('YYYY-MM')
              : null
            this.$store.dispatch('joinus/SET_Two_Form', {
              data: params
            })
          } else {
            resolve(false)
            // this.$bvToast.toast(this.$t('請補全信息'), {
            //   toaster: 'b-toaster-top-center',
            //   autoHideDelay: 5000
            // })
            this.$emit('modalShow', this.$t('請補全信息'))
          }
        })
      })
    },
    onSubmit2() {
      return new Promise(resolve => {
        // this.$validator.validate()((valid) => {
        //     if (valid) {
        //         resolve(true)
        //          this.$store.dispatch('page/setTwoForm', { data: this.form })
        //     } else {
        //         resolve(false)
        //     }
        // })
        resolve(true)
        this.$store.dispatch('page/setTwoForm', {
          data: this.form
        })
      })
    },
    autoWriteHandle() {
      this.form = {
        qualification: '1',
        schoolName: 'xxxxxxxxxxx',
        profession: 'xxxxxxx',
        schoolTimeStart: '2001-06',
        schoolTimeEnd: '2001-06',
        achievements: '8.8',
        scholarship: '0',

        highSchoolName: 'zh',
        highSchoolGraduation: moment('2001-06'),
        highSchoolAchievements: '9',
        workList: [
          {
            companyName: '12',
            companyPosition: '23',
            officeDateStart: '23',
            officeDateEnd: '23',
            description: '23'
          }
        ]
      }
    }
  },
  beforeCreate() {},
  created() {},
  mounted() {
    this.autoWrite = JSON.parse(sessionStorage.getItem('setting'))['auto-write']
  }
}
</script>
