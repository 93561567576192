<template>
  <b-container>
    <h1 class="my-4" v-if="page">{{ $t('加入大豐') }}</h1>
    <div class="join-us">
      <template v-if="content">
        <StepOne v-show="currentStep === 1" ref="step1" :content="content" @modalShow="modalShow" />
      </template>
      <template v-if="content">
        <StepTwo v-show="currentStep === 2" ref="step2" :content="content" @modalShow="modalShow" />
      </template>
      <template v-if="content">
        <StepThird v-show="currentStep === 3" ref="step3" :content="content" @modalShow="modalShow" />
      </template>
      <template v-if="content">
        <StepFourth
          v-show="currentStep === 4"
          ref="step4"
          :content="content"
          :value.sync="captcha"
          @modalShow="modalShow"
        />
      </template>
    </div>
    <b-container class="d-flex my-5 justify-content-center">
      <template v-if="currentStep !== 1">
        <div class="prev-step mx-1">
          <b-button @click="prev" pill>{{ $t('上一頁') }}</b-button>
        </div>
      </template>
      <div class="next-step mx-1" v-if="currentStep !== 4">
        <b-button @click="next" pill>{{ $t('下一頁') }}</b-button>
      </div>
      <div class="preview mx-1" v-if="currentStep === 4">
        <b-button @click="preview" pill :disabled="disabled">{{ $t('送出') }}</b-button>
      </div>
    </b-container>
    <b-modal id="modal-scoped" centered hide-footer hide-header :no-close-on-backdrop="true">
      <div class="text-center">{{ $t('提交成功') }}!<br />{{ $t('感謝你的填寫') }}</div>
      <div class="text-center">
        <b-button class="w-25 mt-4" @click="handleBack()">{{ $t('返回') }}</b-button>
      </div>
    </b-modal>
    <b-modal id="modal-error" centered hide-footer hide-header>
      <div class="text-center">{{ errorMes }}</div>
      <div class="text-center">
        <b-button class="w-25 mt-4" @click.stop="hideModal('modal-error')">{{ $t('返回') }}</b-button>
      </div>
    </b-modal>
    <b-modal id="modal-tips" ref="modaltips" centered hide-footer hide-header :no-close-on-backdrop="true">
      <div class="text-center">{{ tipsMes }}</div>
      <div class="text-center">
        <b-button class="w-25 mt-4" @click.stop="hideModal('modal-tips')">{{ $t('返回') }}</b-button>
      </div>
    </b-modal>
  </b-container>
</template>

<script>
import StepOne from './stepOne'
import StepTwo from './stepTwo'
import StepThird from './stepThird'
import StepFourth from './stepFourth'
import { TakeSubmit } from '@/api/join_us'
import { mapState } from 'vuex'
import updateSeoInfo from '@/utils/meta'
// import Lifecycle from '@/mixins/lifecycle'
import $ from 'jquery'
export default {
  components: {
    StepOne,
    StepTwo,
    StepThird,
    StepFourth
  },
  data() {
    return {
      isMounted: false,
      currentStep: 1,
      disabled: false,
      content: null,
      captcha: false,
      errorMes: '',
      tipsMes: ''
    }
  },
  watch: {
    currentStep(n) {
      if (n) window.scrollTo(0, 0)
    }
  },
  computed: {
    ...mapState('joinus', ['joinUs', 'joinUsOneForm', 'joinUsTwoForm', 'joinUsThirdForm', 'joinUsFourthForm']),
    ...mapState('common', ['page'])
  },
  methods: {
    fetchData() {
      let path = this.$route.path.replace(`/${this.$route.params.lang}/`, '')
      path = path.split('/')[path.split('/').length - 1]
      path = path.replace(`preview/`, '')
      const cookies = this.$ssrContext && this.$ssrContext.cookies
      const { preview, id } = this.$route.query
      return new Promise((resolve, reject) => {
        Promise.all([
          this.fetchInitData(),
          this.$store.dispatch('common/FETCH_PAGE', {
            path,
            cookies,
            preview,
            id
          }),
          this.$store.dispatch('joinus/FETCH_APPLY_JOBS', {
            cookies,
            path
          }),
          this.$store.dispatch('joinus/FETCH_REGION_LIST', {
            cookies,
            path
          }),
          this.$store.dispatch('joinus/FETCH_NATION_LIST', {
            cookies,
            path
          })
        ])
          .finally(() => {
            this.hanldeInit()
            resolve(updateSeoInfo.call(this))
          })
          .catch(reject)
      })
    },
    async hanldeInit() {
      if (await this.joinUs) {
        this.jobsArr = this.joinUs.jobs
        this.content = this.joinUs
      }
    },
    prev() {
      this.currentStep--
    },
    async next() {
      this.nextLoading = true
      let steps = 'step' + this.currentStep
      let result = await this.$refs[steps].onSubmit()
      if (result) {
        this.currentStep++
      } else {
      }
      this.nextLoading = false
    },
    async preview(evt) {
      evt.preventDefault()
      let steps = 'step' + this.currentStep
      let result = await this.$refs[steps].onSubmit()
      if (result) {
        const merge_data = Object.assign(
          this.joinUsOneForm.data,
          this.joinUsTwoForm.data,
          this.joinUsThirdForm.data,
          this.joinUsFourthForm.data
        )
        // console.log(merge_data)
        // let path = this.$route.path.replace(`/${this.$route.params.lang}/`, '')
        this.disabled = true
        TakeSubmit({}, merge_data)
          .then(res => {
            if (res.code == 0) {
              this.$bvModal.show('modal-scoped')
            } else {
              // this.errorMes = res.message
              // this.$bvToast.toast(res.message, {
              //   autoHideDelay: 5000
              // })
              this.$bvModal.show('modal-error')
              if (res.message == 'Captcha Invalid') {
                this.errorMes = this.$t('Captcha Invalid')
                this.captcha = true
                this.$refs.step4.reloadCaptcha()
              } else {
                this.errorMes = this.$t('系統繁忙，請稍候再試')
              }
              this.disabled = false
            }
            // alert('提交成功')
          })
          .catch(err => {
            this.errorMes = err.message
            // this.$bvToast.toast(res.message, {
            //   autoHideDelay: 5000
            // })
            this.$bvModal.show('modal-error')
            this.disabled = false
          })
      } else {
        this.errorScroll()
      }
      // alert(JSON.stringify(this.form))
    },
    handleBack() {
      this.$router.push({
        name: 'join-us'
      })
    },
    hideModal(el) {
      console.log(el)
      // this.$refs.modaltips.hide()
      this.$bvModal.hide(el)
      setTimeout(() => {
        this.errorScroll()
      }, 500)
    },
    errorScroll() {
      let errEle = document.querySelector('.is-invalid')
      if (errEle) {
        window.scroll({
          top: $('.is-invalid').offset().top - 200,
          behavior: 'smooth'
        })
      }
    },
    onSubmit(evt) {
      evt.preventDefault()
      // alert(JSON.stringify(this.form))
    },
    onReset(evt) {
      evt.preventDefault()
      // Reset our form values
      this.form = {}
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    },
    modalShow(msg) {
      console.log('msg', msg)
      this.tipsMes = msg
      this.$bvModal.show('modal-tips')
    }
  },
  seoInfo() {
    try {
      return {
        title: this.page.metaTitle,
        description: this.page.metaDescription || this.page.metaTitle,
        keywords: this.page.metaKeywords || this.page.metaTitle,
        metas: this.page.metaExtras
      }
    } catch (err) {
      this.$bvToast.toast(err, {
        autoHideDelay: 5000
      })
    }
  },
  beforeCreate() {},
  created() {},
  serverPrefetch() {},
  mounted() {
    this.fetchData()
    this.isMounted = true
  }
}
</script>
