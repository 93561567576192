<template>
  <div>
    <b-container class="mt-4">
      <b-form ref="stepForm" v-if="show" class="my-5">
        <!-- <div @click="autoWriteHandle">{{ $t('自動填寫') }}</div> -->
        <div @click="autoWriteHandle" v-show="autoWrite == 'true' ? true : false">{{ $t('自動填寫') }}</div>
        <div class="my-4">
          <h4 class=" type-txt">{{ $t('專業資格') }}</h4>
          <p>( {{ $t('註：如沒有請跳過此欄填寫') }} )</p>
        </div>
        <b-card>
          <div v-for="(professionallist, key) in form.professionalList" :key="key">
            <b-form-group
              :label="`${$t('專業資格證書')}`"
              label-for="professionalqualificationcert"
              label-cols-sm="4"
              label-cols-lg="2"
            >
              <b-form-input
                maxlength="40"
                id="professionalqualificationcert"
                v-model="professionallist.professionalQualificationCert"
                disabled-field="notEnabled"
                name="professionalqualificationcert"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              :label="`${$t('專業資格狀態')}`"
              label-for="professionalqualificationcertstatus"
              label-cols-sm="4"
              label-cols-lg="2"
            >
              <b-form-select
                id="professionalqualificationcertstatus"
                v-model="professionallist.professionalQualificationCertStatus"
                :options="professionalQualificationCertOptions"
                required
                disabled-field="notEnabled"
              ></b-form-select>
            </b-form-group>
            <!-- <b-form-group
              :label="`${$t('其他專業資格')}:`"
              label-for="otherqualificationcert"
              label-cols-sm="4"
              label-cols-lg="2"
            >
              <b-form-input
                id="otherqualificationcert"
                v-model="professionallist.otherQualificationCert"
                required
              ></b-form-input>
            </b-form-group> -->

            <b-form-group
              :label="`${$t('發出機構')}`"
              label-for="otherqualificationby"
              label-cols-sm="4"
              label-cols-lg="2"
            >
              <b-form-input
                maxlength="30"
                id="otherqualificationby"
                v-model="professionallist.otherQualificationBy"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group
              :label="`${$t('取得時間')}`"
              label-for="otherqualificationat"
              label-cols-sm="4"
              label-cols-lg="2"
            >
              <!--<b-form-datepicker
            id="otherqualificationat"
            required
            placeholder="選擇日期"
            o-model="form.otherQualificationAt"
            class="mb-2"
            right
            :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
          />-->
              <DatePicker
                v-model="professionallist.otherQualificationAt"
                :class="['w-100']"
                :show-today="false"
                format="YYYY-MM-DD"
                size="large"
                :placeholder="`${$t('選擇日期')}`"
                :defaultPickerValue="currentDay"
              />
            </b-form-group>
            <hr class="my-5" />
          </div>
          <b-button
            class="m-auto d-block"
            style="border:none; transition:none;box-shadow:none;"
            :pressed="true"
            @click="addListForm"
            >{{ $t('新增專業資格') }}</b-button
          >
        </b-card>
        <h4 class="my-4 type-txt">{{ $t('語言能力') }}</h4>
        <b-card>
          <b-form-group
            :label="`${$t('語言表達能力')}`"
            label-for="reading"
            label-cols-sm="12"
            label-cols-lg="12"
            class="mb-4 _required"
          >
            <b-container class="px-0">
              <table class="w-100">
                <tr>
                  <th width="20%"></th>
                  <th>
                    <div class="d-flex w-100 justify-content-around">
                      <div>{{ $t('流利') }}</div>
                      <div>{{ $t('良好') }}</div>
                      <div>{{ $t('一般') }}</div>
                      <div>{{ $t('略懂') }}</div>
                      <div>{{ $t('不懂') }}</div>
                    </div>
                  </th>
                </tr>
                <tr>
                  <td>{{ $t('廣東話') }}</td>
                  <td>
                    <b-form-radio-group
                      class="w-100 d-flex justify-content-around"
                      id="r_tw"
                      v-validate="'required'"
                      name="r_tw"
                      v-model="form.readingTw"
                      :class="errors.has('r_tw') && 'is-invalid'"
                    >
                      <b-form-radio
                        v-for="index in 5"
                        :value="6 - index"
                        :key="`r_tw_${index}`"
                        class="mx-0"
                      ></b-form-radio>
                    </b-form-radio-group>
                    <b-form-invalid-feedback :state="errors.has('r_tw')">
                      {{ errors.first('r_tw') }}
                    </b-form-invalid-feedback>
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('普通話') }}</td>
                  <td>
                    <b-form-radio-group
                      class="w-100 d-flex justify-content-around"
                      id="r_zh"
                      v-validate="'required'"
                      name="r_zh"
                      v-model="form.readingZh"
                      :class="errors.has('r_zh') && 'is-invalid'"
                    >
                      <b-form-radio
                        v-for="index in 5"
                        :value="6 - index"
                        :key="`r_zh_${index}`"
                        class="mr-0"
                      ></b-form-radio>
                    </b-form-radio-group>
                    <b-form-invalid-feedback :state="errors.has('r_zh')">
                      {{ errors.first('r_zh') }}
                    </b-form-invalid-feedback>
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('英語') }}</td>
                  <td>
                    <b-form-radio-group
                      class="w-100 d-flex justify-content-around"
                      id="r_en"
                      v-validate="'required'"
                      name="r_en"
                      v-model="form.readingEn"
                      :class="errors.has('r_en') && 'is-invalid'"
                    >
                      <b-form-radio
                        v-for="index in 5"
                        :value="6 - index"
                        :key="`r_en_${index}`"
                        class="mr-0"
                      ></b-form-radio>
                    </b-form-radio-group>
                    <b-form-invalid-feedback :state="errors.has('r_en')">
                      {{ errors.first('r_en') }}
                    </b-form-invalid-feedback>
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('葡語') }}</td>
                  <td>
                    <b-form-radio-group
                      class="w-100 d-flex justify-content-around"
                      id="r_pt"
                      v-validate="'required'"
                      name="r_pt"
                      v-model="form.readingPt"
                      :class="errors.has('r_pt') && 'is-invalid'"
                    >
                      <b-form-radio
                        v-for="index in 5"
                        :value="6 - index"
                        :key="`r_pt_${index}`"
                        class="mr-0"
                      ></b-form-radio>
                    </b-form-radio-group>
                    <b-form-invalid-feedback :state="errors.has('r_pt')">
                      {{ errors.first('r_pt') }}
                    </b-form-invalid-feedback>
                  </td>
                </tr>
              </table>
            </b-container>
          </b-form-group>

          <b-form-group
            :label="`${$t('書面表達能力')}`"
            label-for="writing"
            label-cols-sm="12"
            label-cols-lg="12"
            class="_required"
          >
            <b-container class="px-0">
              <table class="w-100">
                <tr>
                  <th width="20%"></th>
                  <th>
                    <div class="d-flex w-100 justify-content-around">
                      <div>{{ $t('流利') }}</div>
                      <div>{{ $t('良好') }}</div>
                      <div>{{ $t('一般') }}</div>
                      <div>{{ $t('略懂') }}</div>
                      <div>{{ $t('不懂') }}</div>
                    </div>
                  </th>
                </tr>
                <tr>
                  <td>{{ $t('中文') }}</td>
                  <td>
                    <b-form-radio-group
                      class="w-100 d-flex justify-content-around"
                      id="w_zh"
                      v-validate="'required'"
                      name="w_zh"
                      v-model="form.writingZh"
                      :class="errors.has('w_zh') && 'is-invalid'"
                    >
                      <b-form-radio
                        v-for="index in 5"
                        :value="6 - index"
                        :key="`w_zh_${index}`"
                        class="mr-0"
                      ></b-form-radio>
                    </b-form-radio-group>
                    <b-form-invalid-feedback :state="errors.has('w_zh')">
                      {{ errors.first('w_zh') }}
                    </b-form-invalid-feedback>
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('英語') }}</td>
                  <td>
                    <b-form-radio-group
                      class="w-100 d-flex justify-content-around"
                      id="w_en"
                      v-validate="'required'"
                      name="w_en"
                      v-model="form.writingEn"
                      :class="errors.has('w_en') && 'is-invalid'"
                    >
                      <b-form-radio
                        v-for="index in 5"
                        :value="6 - index"
                        :key="`w_en_${index}`"
                        class="mr-0"
                      ></b-form-radio>
                    </b-form-radio-group>
                    <b-form-invalid-feedback :state="errors.has('w_en')">
                      {{ errors.first('w_en') }}
                    </b-form-invalid-feedback>
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('葡語') }}</td>
                  <td>
                    <b-form-radio-group
                      class="w-100 d-flex justify-content-around"
                      id="w_pt"
                      v-validate="'required'"
                      name="w_pt"
                      v-model="form.writingPt"
                      :class="errors.has('w_pt') && 'is-invalid'"
                    >
                      <b-form-radio
                        v-for="index in 5"
                        :value="6 - index"
                        :key="`w_pt_${index}`"
                        class="mr-0"
                      ></b-form-radio>
                    </b-form-radio-group>
                    <b-form-invalid-feedback :state="errors.has('w_pt')">
                      {{ errors.first('w_pt') }}
                    </b-form-invalid-feedback>
                  </td>
                </tr>
              </table>
            </b-container>
          </b-form-group>
        </b-card>
        <h4 class="my-4 type-txt">{{ $t('其他資料') }}</h4>
        <b-card>
          <b-form-group
            :label="`${$t('本行任職之親屬/介紹人')}`"
            label-for="hasintroducer"
            label-cols-sm="4"
            label-cols-lg="2"
            class="align-items-center _required"
          >
            <b-form-radio-group
              v-model="form.hasIntroducer"
              id="hasintroducer"
              v-validate="'required'"
              name="hasintroducer"
              :class="errors.has('hasintroducer') && 'is-invalid'"
            >
              <b-form-radio :value="false">{{ $t('沒有') }}</b-form-radio>
              <b-form-radio :value="true">{{ $t('有') }} {{ $t('( 請列舉親屬／介紹人姓名及關係 )') }}</b-form-radio>
            </b-form-radio-group>
            <b-form-input
              maxlength="30"
              id="hasintroducercontent"
              v-if="form.hasIntroducer == true"
              v-model="form.hasintroducercontent"
              disabled-field="notEnabled"
              v-validate="'required'"
              name="hasintroducercontent"
              :class="[errors.has('hasintroducercontent') && 'is-invalid', 'mt-2']"
            ></b-form-input>
            <b-form-invalid-feedback :state="errors.has('hasintroducercontent')">
              {{ errors.first('hasintroducercontent') }}
            </b-form-invalid-feedback>

            <b-form-invalid-feedback :state="errors.has('hasintroducer')">
              {{ errors.first('hasintroducer') }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group :label="`${$t('其他補充')}`" label-for="supplement" label-cols-sm="4" label-cols-lg="2">
            <b-form-textarea
              maxlength="60"
              id="supplement"
              v-model="form.supplement"
              required
              no-resize
              rows="6"
              no-auto-shrink
              :placeholder="`${$t('申請人可於空格內填寫其他補充資料')}`"
            ></b-form-textarea>
          </b-form-group>
        </b-card>
      </b-form>
    </b-container>
  </div>
</template>

<script>
// import { fetchContent } from '@/api/page'
import { DatePicker } from 'ant-design-vue'
import moment from 'moment'
export default {
  components: {
    DatePicker
  },
  data() {
    return {
      form: {
        professionalList: [
          {
            professionalQualificationCert: null,
            professionalQualificationCertStatus: null,
            // otherQualificationCert: '',
            otherQualificationBy: '',
            otherQualificationAt: ''
          }
        ],
        readingTw: '',
        readingZh: '',
        readingEn: '',
        readingPt: '',
        writingZh: '',
        writingEn: '',
        writingPt: '',
        foreignName: '',
        hasIntroducer: false,
        hasintroducercontent: '',
        supplement: ''
      },
      professionalQualificationCertOptions: [
        {
          text: this.$t('請選擇'),
          value: null,
          notEnabled: true
        },
        {
          text: this.$t('已取得證書'),
          value: 0
        },
        {
          text: this.$t('已通過全部考試'),
          value: 1
        },
        {
          text: this.$t('已通過部分考試'),
          value: 2
        },
        {
          text: this.$t('修讀中'),
          value: 3
        }
      ],
      show: true,
      resetData: null,
      autoWrite: false
    }
  },
  computed: {
    currentDay() {
      return moment().subtract(0, 'days')
    }
  },
  methods: {
    disabledDate(current) {
      return current && current > moment().subtract(1, 'days')
    },
    addListForm() {
      this.form.professionalList.push({
        professionalQualificationCert: null,
        professionalQualificationCertStatus: null,
        // otherQualificationCert: '',
        otherQualificationBy: '',
        otherQualificationAt: ''
      })
    },
    onSubmit() {
      return new Promise(resolve => {
        this.$validator.validate().then(validate => {
          if (validate) {
            resolve(true)
            const params = Object.assign({}, this.form)

            // params.otherQualificationAt = params.otherQualificationAt
            //   ? moment(params.otherQualificationAt).format('YYYY-MM-DD')
            //   : null

            params.professionalList.forEach((item, index) => {
              item.otherQualificationAt = item.otherQualificationAt
                ? moment(item.otherQualificationAt).format('YYYY-MM-DD')
                : null
            })
            this.$store.dispatch('joinus/SET_Third_Form', {
              data: params
            })
          } else {
            resolve(false)
            // this.$bvToast.toast(this.$t('請補全信息'), {
            //   toaster: 'b-toaster-top-center',
            //   autoHideDelay: 5000
            // })
            this.$emit('modalShow', this.$t('請補全信息'))
          }
        })
      })
    },
    autoWriteHandle() {
      this.form = {
        professionalList: [
          {
            professionalQualificationCert: '123123',
            professionalQualificationCertStatus: null,
            // otherQualificationCert: '',
            otherQualificationBy: '',
            otherQualificationAt: ''
          }
        ],
        readingTw: '3',
        readingZh: '3',
        readingEn: '3',
        readingPt: '3',
        writingZh: '3',
        writingEn: '3',
        writingPt: '3',
        hasIntroducer: false
      }
    }
  },
  beforeCreate() {},
  created() {},
  mounted() {
    this.autoWrite = JSON.parse(sessionStorage.getItem('setting'))['auto-write']
  }
}
</script>
